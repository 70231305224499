// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

//import store from '@/store/store';

export async function disableOrganizations(organization) {
	const controller = '/pmorganization/disableOrganizations';
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.disabled = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableOrganizations(organization) {
	const controller = '/pmorganization/enableOrganizations';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.disabled = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableSatelliteImages(organization) {
	const controller = '/pmorganization/disableSatelliteImages';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.usesatelliteimages = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableSatelliteImages(organization) {
	console.log('enableSatelliteImages');

	const controller = '/pmorganization/enableSatelliteImages';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.usesatelliteimages = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowNotifications(organization) {
	const controller = '/pmorganization/disableShowNotifications';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.shownotifications = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowNotifications(organization) {
	const controller = '/pmorganization/enableShowNotifications';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.shownotifications = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowAqis(organization) {
	const controller = '/pmorganization/disableShowAqis';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showaqi = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowAqis(organization) {
	const controller = '/pmorganization/enableShowAqis';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showaqi = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowExternalLayers(organization) {
	const controller = '/pmorganization/disableShowExternalLayers';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showexternallayers = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowExternalLayers(organization) {
	const controller = '/pmorganization/enableShowExternalLayers';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showexternallayers = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowGeoJsonLayers(organization) {
	const controller = '/pmorganization/disableShowGeoJsonLayers';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showgeojsonlayers = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowGeoJsonLayers(organization) {
	const controller = '/pmorganization/enableShowGeoJsonLayers';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.showgeojsonlayers = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableSatelliteImagesCompareMap(organization) {
	const controller = '/pmorganization/disableSatelliteImages';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.usesatelliteimagescomparemap = 0;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableSatelliteImagesCompareMap(organization) {
	console.log('enableSatelliteImagesCompareMap');

	const controller = '/pmorganization/enableSatelliteImages';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		organization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
				/* var session = store.getters.getUserProperties;
				session.usesatelliteimagescomparemap = 1;
				store.commit('setUserProperties', session); */
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
