import {
	disableOrganizations,
	enableOrganizations,
	disableSatelliteImages,
	enableSatelliteImages,
	disableSatelliteImagesCompareMap,
	enableSatelliteImagesCompareMap,
	disableShowNotifications,
	enableShowNotifications,
	disableShowAqis,
	enableShowAqis,
	disableShowExternalLayers,
	enableShowExternalLayers,
	disableShowGeoJsonLayers,
	enableShowGeoJsonLayers
} from '@/api/pmorganization';

// Actions for enable/disable an organization
const disableOrganization = {
	id: 'disableOrganization',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableOrganization',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableOrganization');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		//const data = await disableOrganizations(body);
		const promise = disableOrganizations(body);
		promise.then((data) => {
			if (data.error) {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title);
			} else {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifySuccess');
				this.$puiNotify.success(message, title);
			}
		});
	}
};

const enableOrganization = {
	id: 'enableOrganization',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableOrganization',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableOrganization');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		//const data = await enableOrganizations(body);
		const promise = enableOrganizations(body);
		promise.then((data) => {
			if (data.error) {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title);
			} else {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifySuccess');
				this.$puiNotify.success(message, title);
			}
		});
	}
};

// Actions for using or not satellite imagery
const disableSatelliteImage = {
	id: 'disableSatelliteImage',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableSatelliteImage',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.usesatelliteimages === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableSatelliteImage');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableSatelliteImages(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableSatelliteImage = {
	id: 'enableSatelliteImage',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableSatelliteImage',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.usesatelliteimages === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableSatelliteImage');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableSatelliteImages(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// Actions for showing or not notifications
const disableShowNotification = {
	id: 'disableShowNotification',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableShowNotification',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.shownotifications === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableShowNotification');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableShowNotifications(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowNotification = {
	id: 'enableShowNotification',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableShowNotification',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.shownotifications === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableShowNotification');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableShowNotifications(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// Actions for showing or not AQI
const disableShowAqi = {
	id: 'disableShowAqi',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableShowAqi',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showaqi === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableShowAqi');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableShowAqis(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowAqi = {
	id: 'enableShowAqi',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableShowAqi',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showaqi === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableShowAqi');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableShowAqis(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// Actions for showing or not External Layers
const disableShowExternalLayer = {
	id: 'disableShowExternalLayer',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableShowExternalLayer',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showexternallayers === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableShowExternalLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableShowExternalLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowExternalLayer = {
	id: 'enableShowExternalLayer',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableShowExternalLayer',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showexternallayers === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableShowExternalLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableShowExternalLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// Actions for showing or not GeoJSON Layers
const disableShowGeoJsonLayer = {
	id: 'disableShowGeoJsonLayer',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableShowGeoJsonLayer',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showgeojsonlayers === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableShowGeoJsonLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableShowGeoJsonLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowGeoJsonLayer = {
	id: 'enableShowGeoJsonLayer',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableShowGeoJsonLayer',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.showgeojsonlayers === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableShowGeoJsonLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableShowGeoJsonLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// Actions for using or not satellite imagery CompareMap
const disableSatelliteImageCompareMap = {
	id: 'disableSatelliteImageCompareMap',
	selectionType: 'multiple',
	label: 'pmorganization.actions.disableSatelliteImage',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.usesatelliteimagescomparemap === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.disableSatelliteImage');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await disableSatelliteImagesCompareMap(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableSatelliteImageCompareMap = {
	id: 'enableSatelliteImageCompareMap',
	selectionType: 'multiple',
	label: 'pmorganization.actions.enableSatelliteImage',
	functionality: 'UPDATE_PMORGANIZATION',
	checkAvailability: function (organization) {
		return organization && organization.usesatelliteimagescomparemap === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmorganization.actions.enableSatelliteImage');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmorganizationid: registries[i].pmorganizationid
			});
		}

		const data = await enableSatelliteImagesCompareMap(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [
		disableOrganization,
		enableOrganization,
		disableSatelliteImage,
		enableSatelliteImage,
		disableSatelliteImageCompareMap,
		enableSatelliteImageCompareMap,
		disableShowNotification,
		enableShowNotification,
		disableShowAqi,
		enableShowAqi,
		disableShowExternalLayer,
		enableShowExternalLayer,
		disableShowGeoJsonLayer,
		enableShowGeoJsonLayer
	]
};
